<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="container-fluid py-4 add-student grade-detail paid-remain">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">
                    {{$t('message.persistanceAverage')}}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="mb-3 col-12 tab-div tab2">
            </div>
            <!-- exceptional attendance row -->
            <div class="" id="">
              <div

              >
               <div
              >
                <div class="row align-center ex-row">
                  <div class="mb-3 col-lg-3">
                    <label> {{$t('message.fromDate')}} <span class="red">*</span></label>
                     <vmd-input id="" type="date" v-model="fromDate" />
                  </div>
                  <div class="mb-3 col-lg-3">
                    <label> {{$t('message.toDate')}} <span class="red">*</span></label>
                     <vmd-input id="" type="date" v-model="toDate" />
                  </div>
                   <div class="mb-3 col-lg-3">
                    <label> {{$t('message.instructor')}}</label>
                    <multiselect
                      class="required form-control"
                      id="grade"
                      v-model="selectedInstructor"
                      :options="instructors"
                      :searchable="true"
                      :close-on-select="true"
                      :placeholder=" $t('message.select') "
                      label="name"
                      :custom-label="customLabel"
                      track-by="id"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                  <div class="text-center col-lg-2">
                    <vmd-button
                      class="my-4 mb-2 width action-btn"
                      variant="gradient"
                      color="primary"
                      @click="filterData()"
                    >
                      {{$t('message.filter')}}</vmd-button
                    >
                  </div>
                </div>
                <div
                  id="print-report"
                  style="margin-top: 40px; "
                >
                  <div
                    class="row align-center"
                    style="margin-top: 40px;"
                  >
                    <div
                      class="text-center col-lg-12 overflow-x"

                    >
                      <table class="b-block table" >
                        <thead>
                          <tr>
                             <th > {{$t('message.students')}}</th>
                            <th >  {{$t('message.subjects')}} </th>
                          </tr>
                        </thead>
                         <tbody>
                          <tr
                            v-for="(item, index) in persistanceAverage"
                            :key="item.id">
                            <td @click="getStudents(index)" class=" total-tex link pointer">{{ item.students_count}}</td>
                            <td class=" total-tex">{{ item.subjects_count}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end -->
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- students modal -->
        <vue-final-modal
      v-model="showModal"
      class="students-payments course-modal avarage-modal"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                   {{$t('message.students')}}
                </h6>
              </div>
              <div class="text-center col-lg-12">
                <table class="table b-block">
                  <thead>
                    <tr>
                      <th>{{$t('message.id')}}</th>
                      <th>{{$t('message.name')}}</th>
                      <th>{{$t('message.mobile')}}</th>
                      <th> {{$t('message.balance')}}</th>
                      <th> {{$t('message.status')}}</th>
                        <th> {{$t('message.lastAttendance')}}</th>
                    </tr>
                  </thead>
                 <tbody v-for="(item, index) in students" :key="index">
                    <tr>
                      <td>
                        {{ item.student_number }}
                      </td>
                      <td>
                        {{ item.student_name }}
                      </td>
                      <td>
                        {{ item.student_mobile }}
                      </td>
                      <td>{{$filters.number(item.wallet)}}</td>
                      <td></td>
                      <td>
                        {{  }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row text-center">
                <div class="text-center col-lg-12">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    @click="showModal = false"
                  >
                    {{$t('message.close')}}
                  </vmd-button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import VmdButton from '@/components/VmdButton.vue'
import VmdInput from '@/components/VmdInput.vue'
import exportFromJSON from 'export-from-json'
import { VueFinalModal } from 'vue-final-modal'
import $ from 'jquery'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
export default {
  name: 'UsersPlacement',
  components: {
    VmdButton,
    VmdInput,
    VueFinalModal
  },
  data () {
    return {
      fromDate: '',
      toDate: '',
      branches: [],
      persistanceAverage: [],
      students: [],
      showModal: false,
      instructors: [],
      selectedInstructor: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}instructors`).then((response) => {
      this.instructors = response.data.data
    })
  },
  methods: {
    filterData (data) {
      this.$http
        .get(
                `${this.$hostUrl}report/levels/enrolled/count?from_date=${this.fromDate}&to_date=${this.toDate}&instructor_id=${this.selectedInstructor?.id || ''}`
        )
        .then((response) => {
          this.persistanceAverage = response.data.data
          // const firstDay = Object.keys(this.monthlyPlacement)[0]
          // this.branches = Object.keys(this.monthlyPlacement[firstDay])
          //   .filter(subkey => !subkey.includes('total'))
        })
    },
    getStudents (index) {
      this.showModal = true
      this.students = this.persistanceAverage[index].students
    },
    downloadFile (report) {
      if (report === 'instructor') {
        $.each(this.students, (key, val) => {
          this.studentsFile.push({
            رقم: val.number,
            الاسم: val.full_name,
            الهاتف: val.mobile,
            المجموعة: val.group_name,
            تكلفة_الدورة: val.course_cost,
            الحضور: val.attend,
            المسدد: val.payed,
            المتبقي: val.remain
          })
        })
        const data = this.studentsFile
        const fileName = ` تقرير المسدد والمتبقي للمدرس : ${this.selectedInstructor.name}- ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
      if (report === 'course') {
        $.each(this.courseStudents, (key, val) => {
          this.studentsFile.push({
            مسلسل: key + 1,
            رقم: val.number,
            الاسم: val.full_name,
            الهاتف: val.mobile,
            المجموعة: val.group_name,
            تكلفة_الدورة: val.course_cost,
            الحضور: val.attend,
            المسدد: val.paid,
            المتبقي: val.remain
          })
        })
        const data = this.studentsFile
        const fileName = ` تقرير المسدد والمتبقي دورة : ${this.course}  - ${today}`
        const exportType = exportFromJSON.types.xls
        if (data) exportFromJSON({ data, fileName, exportType })
      }
    },
    printReport (data) {
      $('.card-footer').hide()
      const options = {
        name: ' Seven Eleven System ',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css',
          'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
          `${window.location.origin}/print-report.css`
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: 'Seven Eleven System' // override the window title
      }
      if (data === 'instructor') {
        setTimeout(this.$htmlToPaper('print-report', options), 10000)
        $('.card-footer').show()
      } else {
        setTimeout(this.$htmlToPaper('print-report2', options), 10000)
        $('.card-footer').show()
      }
    }
  }
}
</script>
<style>
#collection-date {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.table > :not(:first-child) {
  border-bottom: 1px solid #f0f2f5 !important;
}
.width {
  width: 100%;
}
.online {
  border: 1px solid #ddd;
}
.times {
  margin-top: 10px !important;
}
.times h5 {
  font-size: 16px !important;
}
.times .red {
  position: relative !important;
}
.width {
  width: 100% !important;
}
.back-color{
  background-color: #f8f9fa !important
}
.total-tex{
  font-weight: bold;
  font-size:16px !important;
  font-family: Montserrat, sans-serif !important;
}
.table td.link{
  color:blue !important;
  text-decoration: none;
}
.avarage-modal .modal-content{
  width:55% !important;
  max-height:680px !important;
   top: 49% !important;
   left:45%;
}
</style>
